((function() {
  jQuery.fn.rotate = function(degrees) {
    $(this).css({'-webkit-transform' : 'rotate('+ degrees +'deg)',
                '-moz-transform' : 'rotate('+ degrees +'deg)',
                '-ms-transform' : 'rotate('+ degrees +'deg)',
                'transform' : 'rotate('+ degrees +'deg)'});
  };
})());

((function() {
  $( document ).ready(function() {
    var hour = 0,
        minute = 0,
        second = 0;

    currentTime();
    function currentTime() { 
      var seconds = new Date().getSeconds();
      var sdegree = seconds * 6;
      second = "rotate(" + sdegree + "deg)";

      var hours = new Date().getHours();
      var mins = new Date().getMinutes();
      var hdegree = hours * 30 + (mins / 2);
      hour = "rotate(" + hdegree + "deg)";

      var mins = new Date().getMinutes();
      var mdegree = mins * 6;
      minute = "rotate(" + mdegree + "deg)";

      moveHour();
      moveMinute();
      moveSecond();
    }

    function moveHour() { 
      $("#breguet-header-hour").css({ "transform": hour });
    }

    function moveMinute() { 
      $("#breguet-header-minute").css({ "transform" : minute });
    }

    function moveSecond() { 
      $("#breguet-header-second").css({ "transform": second });
    }

    setInterval( function() {
        var seconds = new Date().getSeconds();
        var sdegree = seconds * 6;
        var srotate = "rotate(" + sdegree + "deg)";
        $("#breguet-header-second").css({ "transform": srotate });
    }, 1000 );
    
    setInterval( function() {
      var hours = new Date().getHours();
      var mins = new Date().getMinutes();
      var hdegree = hours * 30 + (mins / 2);
      var hrotate = "rotate(" + hdegree + "deg)";
      $("#breguet-header-hour").css({ "transform": hrotate});
    }, 1000 );

    setInterval( function() {
      var mins = new Date().getMinutes();
      var mdegree = mins * 6;
      var mrotate = "rotate(" + mdegree + "deg)";
      $("#breguet-header-minute").css({ "transform" : mrotate });
    }, 1000 );
  });
})());

((function () {
  $(document).ready(function () {
    var hour = 0,
        minute = 0,
        second = 0;

      function moveHour() {
        $("#breguet-banner-hour").css({
            "transform": hour
        });
      }

      function moveMinute() {
        $("#breguet-banner-minute").css({
            "transform": minute
        });
      }

      function moveSecond() {
        $("#breguet-banner-second").css({
            "transform": second
        });
      }

      function showDate() {
        var date = $('#breguet-banner-date');
        var today = new Date();
        var dd = today.getDate();
        var HTMLDate = '';
        if (dd < 10) {
            dd = '0' + dd;
        }
        HTMLDate += '<span>';
        HTMLDate += dd;
        HTMLDate += '</span>';
        date.html(HTMLDate);
      }
      showDate();

      // set GMT follow DOC
      function checkGMT() {
        var numGMT = (moment().format('Z')).split(":");
        numGMT = numGMT[0];
        var location = moment.tz.guess();

        var dateObj = new Date();
        var month = dateObj.getMonth() + 1; //months from 1-12
        var day = dateObj.getDate();
        var year = dateObj.getFullYear();

        if (inRange([year, month, day], [2018, 6, 5], [2018, 8, 30])) {
          numGMT = '-3';
          location = "America/Sao_Paulo";
        }
        if (inRange([year, month, day], [2018, 8, 31], [2018, 10, 4])) {
          numGMT = '-5';
          location = "America/Chicago";
        }
        if (inRange([year, month, day], [2018, 10, 5], [2018, 11, 21])) {
          numGMT = '-10';
          location = "Pacific/Honolulu";
        }
        if (inRange([year, month, day], [2018, 11, 22], [2018, 12, 23])) {
          numGMT = '+13';
          location = "Pacific/Fakaofo";
        }
        if (inRange([year, month, day], [2018, 12, 24], [2019, 1, 30])) {
          numGMT = '+12';
          location = "Pacific/Funafuti";
        }
        if (inRange([year, month, day], [2019, 2, 1], [2019, 5, 11])) {
          numGMT = '+11';
          location = "Pacific/Noumea";
        }
        if (inRange([year, month, day], [2019, 5, 12], [2019, 6, 15])) {
          numGMT = '+10';
          location = "Asia/Vladivostok";
        }
        if (inRange([year, month, day], [2019, 6, 16], [2020, 4, 2])) {
          numGMT = '+8';
          location = "Asia/Singapore";
        }
        if (inRange([year, month, day], [2020, 4, 3], [2020, 10, 24])) {
          numGMT = '+9';
          location = "Asia/Tokyo";
        }
        if (inRange([year, month, day], [2020, 10, 25], [2020, 11, 24])) {
          numGMT = '+5.5';
          location = "Asia/Colombo";
        }
        if (inRange([year, month, day], [2020, 11, 25], [2021, 4, 15])) {
          numGMT = '+4';
          location = "Asia/Dubai";
        }

        currentTime(location);

        return numGMT;
      }

      function convert(d) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp) 
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
          d.constructor === Date ? d :
          d.constructor === Array ? new Date(d[0], d[1], d[2]) :
          d.constructor === Number ? new Date(d) :
          d.constructor === String ? new Date(d) :
          typeof d === "object" ? new Date(d.year, d.month, d.date) :
          NaN
        );
      }

      function inRange(d, start, end) {
        // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
          isFinite(d = convert(d).valueOf()) &&
          isFinite(start = convert(start).valueOf()) &&
          isFinite(end = convert(end).valueOf()) ?
          start <= d && d <= end :
          NaN
        );
      }

      function showGMT() {
        var HTMLInner = '';
        var numGMT = checkGMT();
        HTMLInner += '<span class="c-breguet-banner__text-gmt">';
        HTMLInner += 'GMT' + numGMT;
        HTMLInner += '<span>';

        var gmt = $('#breguet-banner-GMT');
        gmt.html(HTMLInner);
      }

      function currentTime(location) {
        var seconds = moment().tz(location).format('ss');
        var sdegree = seconds * 6;
        second = "rotate(" + sdegree + "deg)";

        var hours = moment().tz(location).format('H');
        var mins = moment().tz(location).format('mm');
        var hdegree = hours * 30 + (mins / 2);
        hour = "rotate(" + hdegree + "deg)";

        var mins = moment().tz(location).format('mm');
        var mdegree = mins * 6;
        minute = "rotate(" + mdegree + "deg)";

        moveHour();
        moveMinute();
        moveSecond();

        countWatch(location);
      }

      function countWatch(location) {
        setInterval(function () {
          var seconds = moment().tz(location).format('ss');
          var sdegree = seconds * 6;
          var srotate = "rotate(" + sdegree + "deg)";
          $("#breguet-banner-second").css({
            "transform": srotate
          });
        }, 1000);

        setInterval(function () {
          var hours = moment().tz(location).format('H');
          var mins = moment().tz(location).format('mm');
          var hdegree = hours * 30 + (mins / 2);
          var hrotate = "rotate(" + hdegree + "deg)";
          $("#breguet-banner-hour").css({
            "transform": hrotate
          });
        }, 1000);

        setInterval(function () {
          var mins = moment().tz(location).format('mm');
          var mdegree = mins * 6;
          var mrotate = "rotate(" + mdegree + "deg)";
          $("#breguet-banner-minute").css({
            "transform": mrotate
          });
        }, 1000);
      }

      showGMT();
  });
})());