$(function() {
  var userAgent = navigator.userAgent.toLowerCase();
  var isIE = userAgent.indexOf("msie") != -1 ? parseInt(userAgent.split("msie")[1]) : userAgent.indexOf("trident") != -1 ? 11 : userAgent.indexOf("edge") != -1 ? 12 : false;
  if (isIE === 11) {
    $('body').addClass('ie11');
    
    $('.gallery-item img').each (function() {
      var $this = $(this);
      var $parent = $this.parent();
      var imgUrl = $this.attr('src');
      $parent.css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('compat-object-fit');
    });

    $('img[class*="wp-image"]').each(function() {
      var $this = $(this);
      if ($this.attr('width')) {
        $this.css('width', $this.attr('width') + 'px');
      }
      if ($this.attr('height')) {
        $this.css('height', $this.attr('height') + 'px');
      }
    });
  }
});