$(document).ready(function () {
  $('.js-scroll-to').click(function (e) {
    e.preventDefault();

    var page = $(this).data('target'),
        duration = $(this).data('duration'),
        menuHeight,
        windowWidth;

    if (typeof(duration) == 'undefined') {
      duration = 750;
    }

    function getWindowWith() {
      windowWidth = $(window).width();
    }

    getWindowWith();

    $(window).resize(function () {
      getWindowWith();
    });

    if (windowWidth >= 720) {
      menuHeight = 75;
    }
    else {
      menuHeight = 50;
    }

    if ($('#wpadminbar').length > 0) {
      menuHeight += 50;
    }

    menuHeight = 0;

    $('html, body').animate({
      scrollTop: $(page).offset().top - menuHeight
    }, duration);

    return false;
  });
});
