//= require slick-carousel/slick/slick.js

$(function() {
  var configuration = {
    'c-press__slides-list': {
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200, // jusqu'à 1320
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 990, // jusqu'à 990
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 660, // jusqu'à 660
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    },
    'c-numbers__items-list': {
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 1100, // jusqu'à 1100
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 880, // jusqu'à 880
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 660, // jusqu'à 660
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 440, // jusqu'à 440
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }
  };

  $('.js-slick-list').each(function() {
    var $this = $(this);

    var key = $.trim($this.attr('class').replace('c-slick__list', '').replace('js-slick-list', ''));

    $this.slick({
      dots: false,
      infinite: false,
      nextArrow: '.c-slick__next',
      prevArrow: '.c-slick__prev',
      speed: 300,
      slidesToShow: configuration[key].slidesToShow,
      slidesToScroll: configuration[key].slidesToScroll,
      responsive: configuration[key].responsive
    });
  });
});